import { template as template_217308b070e14c96873d73ecc03742fb } from "@ember/template-compiler";
const FKLabel = template_217308b070e14c96873d73ecc03742fb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
