import { template as template_3d7d093d36164f8487e0bd490ef22e21 } from "@ember/template-compiler";
const SidebarSectionMessage = template_3d7d093d36164f8487e0bd490ef22e21(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
