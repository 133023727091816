import { template as template_f144be46b22d4125ab9e7756b9c8c594 } from "@ember/template-compiler";
const EmptyState = template_f144be46b22d4125ab9e7756b9c8c594(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
