import { template as template_7ef2e123e1344f48bef41cf8438766bd } from "@ember/template-compiler";
const FKControlMenuContainer = template_7ef2e123e1344f48bef41cf8438766bd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
